<template>
  <div>
    <grid_v_card :data="fetchedData" no_padding :columns="columns" :loading="loading" label="Open Matters"
      @update="fetchIt(true)" :export_name="dateFormat() + ' All Matters'" :time="time"></grid_v_card>
    <specific_association v-model="isModalOpen" :current="current"></specific_association>
  </div>
</template>

<script>
import DataGrid from "@/mixins/DataGrid";
import { lauderLarken } from "@/plugins/axios";
import PDF from "@/mixins/PDF";
import { jsPDF } from "jspdf";
import { hasALocalPyServer } from "@/globals";
export default {
  mixins: [DataGrid, PDF],
  name: "all_association_data_grid",
  data() {
    return {
      increamntor: 250,
      config: {
        dbAllKey: "getAllAssociations",
        dbCertainKey: "getCertainAssociations",
        objectDbKey: "asses",
        axiosLink: "getAssociationsData",
        dbPutKey: "setCertainAssociations"
      },
      current: {},
      liensData: {
        params: null,
        data: null,
        divided: null,
        noPrior: false
      },
      columns: [
        {
          headerName: "Select",
          minWidth: 500,
          field: "id",
          type: "text",
          exportable: false,
          colId: "Ass_Id",
          resizable: false,
          //autoHeight: true,
          //autoWedith: true,
          cellRenderer: params => {
            const link = document.createElement("a");
            link.innerText = "Details";
            link.className =
              "v-btn v-btn--rounded v-btn--tile theme--dark v-size--small secondary ml-3 col col-auto flex-item ";
            const link2 = document.createElement("a");
            link2.innerText = "Report";
            link2.className =
              "v-btn v-btn--rounded v-btn--tile theme--dark v-size--small secondary ml-3 pr-5 col col-auto flex-item";
            const div = document.createElement("div");

            div.className = "selectdiv";

            const LiensLink = document.createElement("a");
            LiensLink.innerText = "Balance";
            if (params.data["Liens"] === 0) {
              LiensLink.className =
                "v-btn v-btn--rounded v-btn--tile theme--dark v-size--small ml-3 col col-auto pl-2 pr-5 flex-item";
              LiensLink.style.backgroundColor = "#9a9a9a";
            } else {
              LiensLink.className =
                "v-btn v-btn--rounded v-btn--tile theme--dark v-size--small secondary ml-3 col-auto col pl-2 pr-5 flex-item";
            }
            LiensLink.addEventListener("click", async e => {
              const downloadLoading = document.createElement("i");
              downloadLoading.className = "fa fa-spinner fa-spin";
              LiensLink.appendChild(downloadLoading);
              try {
                e.preventDefault();
                await this.lienHandler(params);
                //this.loading = true;
              } catch (e) {
                this.$store.commit("snackbar", {
                  appear: true,
                  timeout: 5000,
                  text: "An Error Occurred. ",
                  color: "error"
                });
                console.log(e);
              } finally {
                if (document.contains(downloadLoading)) {
                  LiensLink.removeChild(downloadLoading);
                }
              }
            });

            div.append(link, link2);
            // eslint-disable-next-line no-constant-condition
            if (true) {
              div.append(LiensLink);
            }
            if (params.value) {
              link.addEventListener("click", e => {
                e.preventDefault();
                this.isModalOpen = true;
                this.current = params.data;
              });
              link2.addEventListener("click", e => {
                e.preventDefault();
                console.log(params.data["Client_Associations"]);
                this.$store.commit("lauderSetSelectedAss", {
                  Client_Associations: params.data["Client_Associations"]
                });
                // this.$store.dispatch("assChanged");
                this.$router.push({
                  name: "lauderLaw---reports",
                  params: { id: params.data }
                });
              });
            }

            return div;
          }
        },
        // {
        //   headerName: "Balance",
        //   field: "id",
        //   type: "text",
        //   exportable: false,
        //   colId: "Balance",
        //   cellRenderer: (params) => {
        //     const LiensLink = document.createElement("a");
        //     LiensLink.innerText = "Balance";
        //     if (params.data["Liens"] === 0) {
        //       LiensLink.className =
        //         "v-btn v-btn--rounded v-btn--tile theme--dark v-size--small ml-3 col col-auto pl-2 pr-2 ";
        //       LiensLink.style.backgroundColor = "#9a9a9a";
        //     } else {
        //       LiensLink.className =
        //         "v-btn v-btn--rounded v-btn--tile theme--dark v-size--small secondary ml-3 col-auto col pl-2 pr-2";
        //     }
        //     LiensLink.addEventListener("click", async (e) => {
        //       const downloadLoading = document.createElement("i");
        //       downloadLoading.className = "fa fa-spinner fa-spin";
        //       LiensLink.appendChild(downloadLoading);
        //       try {
        //         e.preventDefault();
        //         await this.lienHandler(params);
        //         //this.loading = true;
        //       } catch (e) {
        //         this.$store.commit("snackbar", {
        //           appear: true,
        //           timeout: 5000,
        //           text: "An Error Occurred. ",
        //           color: "error",
        //         });
        //         console.log(e);
        //       } finally {
        //         if (document.contains(downloadLoading)) {
        //           LiensLink.removeChild(downloadLoading);
        //         }
        //       }
        //     });
        //     //todo::remove it to put it
        //     //if (process.env.NODE_ENV === "development") {
        //     // eslint-disable-next-line no-constant-condition
        //     if (true) {
        //       return LiensLink;
        //     }
        //     return null;
        //   },
        // },
        {
          headerName: "Name",
          field: "Home Owner",
          type: "text",
          colId: "Ass_Owner",
          minWidth: 250
        },
        {
          headerName: "Status",
          field: "Status",
          type: "status",
          colId: "Ass_Status",
          minWidth: 250
        },
        {
          headerName: "Address",
          field: "Client Address",
          type: "text",
          colId: "Ass_Address"
        },
      
        {
          headerName: "row_sys",
          field: "row_sys",
          type: "text",
          colId: "Ass_SisId",
          hide: true
        }
        // {
        //   headerName: "Balance",
        //   colId: "AssBal"
        // }
      ],
      ltimes: 0
    };
  },
  methods: {
    getIncreamntor(increament = 0) {
      this.increamntor += increament;
      return this.increamntor;
    },
    secondColumn(doc, yMargin = 70, mainX = 137) {
      if (Object.keys(this.liensData.divided.JDG["1"]).length) {
        const ob = this.liensData.divided.JDG["1"];
        doc.setTextColor("#000");
        doc.setFontSize(12);
        doc.setFont("boldFont", "bold");
        doc.text("1st Judgment", mainX, yMargin);
        doc.setFont("normalFont", "normal");
        doc.setFontSize(9);
        doc.text("(including assessments ", mainX, yMargin + 10);
        doc.text(
          "through " + ob["WP JDG1 PJ principal through DA"] + ")",
          mainX,
          yMargin + 20
        );

        doc.setFont("normalFont", "normal");
        doc.setFontSize(9);
        let arr = [
          [ob["WP JDG1 Prior judgment principal DA"], 0],
          [ob["WP JDG1 Principal judgment table CUR"], 0],
          // [
          //   //[ob["WP JDG1 PJ principal through DA"], -10],
          //   [ob["WP JDG1 Principal judgment table CUR"], 35]
          // ],
          [ob["WP JDG1 Prior judgment interest CUR"], 0],
          [ob["WP JDG1 Prior judgment atty fees amount CUR"], 0],
          [ob["WP JDG1 Prior judgment costs CUR"], 0],
          [ob["WP JDG1 Prior judg garn costs collected total CUR"], 0],
          // [
          // ["(" + ob["WP JDG1 Interest through DA"] + ") ", -10],
          // ],
          [ob["WP JDG1 Post judgment interest CUR"], 0],
          [ob["WP JDG1 Prior judgment payment total CUR"], 0, true],
          [ob["WP JDG1 Prior judgment payments CUR"], 0],
          [ob["WP JDG1 Prior judg settle credit total CUR"], 0, true],
          [ob["WP JDG1 Judgment balance CUR"], 0]
        ];
        this.rowList(18, 20, 0, mainX, yMargin + 20, arr, doc);
      }
    },
    thirdColumn(doc, yMargin = 50, mainX = 247) {
      if (Object.keys(this.liensData.divided.JDG["2"]).length) {
        const ob = this.liensData.divided.JDG["2"];
        doc.setTextColor("#000");
        doc.setFontSize(12);
        doc.setFont("boldFont", "bold");
        doc.text("2nd Judgment", mainX, yMargin);
        doc.setFont("normalFont", "normal");
        doc.setFontSize(9);
        doc.text("(including assessments ", mainX, yMargin + 10);
        doc.text(
          "through " + ob["WP JDG2 PJ principal through DA"] + ")",
          mainX,
          yMargin + 20
        );
        doc.setFont("normalFont", "normal");
        doc.setFontSize(9);

        let arr = [
          [ob["WP JDG2 Prior judgment principal DA"], 0],
          [ob["WP JDG2 Principal judgment table CUR"], 0],
          [ob["WP JDG2 Prior judgment interest CUR"], 0],
          [ob["WP JDG2 Prior judgment atty fees amount CUR"], 0],
          [ob["WP JDG2 Prior judgment costs CUR"], 0],
          [ob["WP JDG2 Prior judg garn costs collected total CUR"], 0],
          // [
          //   ["(" + ob["WP JDG2 Interest through DA"] + ") ", -25],
          //
          // ],
          [ob["WP JDG2 Post judgment interest CUR"], 0],
          [ob["WP JDG2 Prior judgment payment total CUR"], 0, true],
          [ob["WP JDG2 Prior judgment payments CUR"], 0],
          [ob["WP JDG2 Prior judg settle credit total CUR"], 0, true],
          [ob["WP JDG2 Judgment balance CUR"], 0]
        ];
        this.rowList(18, 20, 0, mainX, yMargin + 20, arr, doc);
      }
    },
    fourthColumn(doc, yMargin = 50, mainX = 357) {
      if (Object.keys(this.liensData.divided.JDG["3"]).length) {
        const ob = this.liensData.divided.JDG["3"];
        doc.setTextColor("#000");
        doc.setFontSize(12);
        doc.setFont("boldFont", "bold");
        doc.text("3rd Judgment", mainX, yMargin);
        doc.setFont("normalFont", "normal");
        doc.setFontSize(9);
        doc.text("(including assessments ", mainX, yMargin + 10);
        doc.text(
          "through " + ob["WP JDG3 PJ principal through DA"] + ")",
          mainX,
          yMargin + 20
        );

        let arr = [
          [ob["WP JDG3 Prior judgment principal DA"], 0],
          [ob["WP JDG3 Principal judgment table CUR"], 0],
          // [
          //   //[ob["WP JDG3 PJ principal through DA"], -25],
          //   [ob["WP JDG3 Principal judgment table CUR"], 20]
          // ],
          [ob["WP JDG3 Prior judgment interest CUR"], 0],
          [ob["WP JDG3 Prior judgment atty fees amount CUR"], 0],
          [ob["WP JDG3 Prior judgment costs CUR"], 0],
          [ob["WP JDG3 Prior judg garn costs collected total CUR"], 0],
          // [
          //   // ["(" + ob["WP JDG3 Interest through DA"] + ") ", -25],
          // ],
          [ob["WP JDG3 Post judgment interest CUR"], 0],
          [ob["WP JDG3 Prior judgment payment total CUR"], 0, true],
          [ob["WP JDG3 Prior judgment payments CUR"], 0],
          [ob["WP JDG3 Prior judg settle credit total CUR"], 0, true],
          [ob["WP JDG3 Judgment balance CUR"], 0]
        ];
        this.rowList(18, 20, 0, mainX, yMargin + 20, arr, doc);
      }
    },
    firstColumn(doc, yMargin = 30, mainX = 17) {
      // if (this.liensData.data['WP JDG Prior CNT'] && this.liensData.data['WP JDG Prior CNT'] > 0){
      doc.setTextColor("#000");
      doc.setFont("boldFont", "bold");
      doc.setFontSize(13);
      doc.text("Judgment:", mainX, yMargin + 10);
      doc.setFont("normalFont", "normal");
      doc.setFontSize(9);
      doc.text("Judgment Entry Date:", mainX, yMargin + 50);
      this.increamntor = 50;
      doc.text(
        //"Principal (including assessments through):",
        "Principal:",
        mainX,
        yMargin + this.getIncreamntor(18)
      );

      doc.text(
        "Interest awarded in judgment:",
        mainX,
        yMargin + this.getIncreamntor(18)
      );
      doc.text("Attorney’s Fees:", mainX, yMargin + this.getIncreamntor(18));
      doc.text("Costs:", mainX, yMargin + this.getIncreamntor(18));
      doc.text("Garnishment Costs:", mainX, yMargin + this.getIncreamntor(18));
      doc.text(
        "Accrued Post-Judgment Interest:",
        mainX,
        yMargin + this.getIncreamntor(18)
      );
      doc.text("Payments:", mainX, yMargin + this.getIncreamntor(18));
      doc.text(
        "Payments Received Prior to Judgment:",
        mainX,
        yMargin + this.getIncreamntor(18)
      );
      doc.text("Adjustment Credits:", mainX, yMargin + this.getIncreamntor(18));
      doc.text("Judgment Balance:", mainX, yMargin + this.getIncreamntor(18));
      // }
    },
    firstPanel(doc) {
      if (
        this.liensData.data["WP Populate Portal Data TF"] &&
        this.liensData.data["WP JDG Prior CNT"]
      ) {
        const count = this.liensData.data["WP JDG Prior CNT"];
        //1-first column
        this.firstColumn(doc, 125);
        //2-socend column
        if (count > 0) this.secondColumn(doc, 135);
        //3-third column
        if (count > 1) this.thirdColumn(doc, 135);
        //4-fourth column
        if (count > 2) this.fourthColumn(doc, 135);
      } else {
        this.liensData.noPrior = true;
      }
    },
    header(doc) {
      const img = new Image();
      img.src = require("@/assets/lueder.jpg");
      const pageWidth = doc.internal.pageSize.getWidth();
      const w = 150;
      const h = 60;
      const marginX = (pageWidth - w) / 2;
      doc.addImage(img, "jpg", marginX, 18, w, h);
      doc.setTextColor("#000");
      doc.setFontSize(15);
      doc.setFont("boldFont", "bold");
      //first panel
      doc.myText("Claim Summary", { align: "center" }, 0, 87);
      doc.setFontSize(9);
      doc.setFont("normalFont", "normal");
      doc.myText(
        this.liensData.params["Matter Reference"],
        { align: "center" },
        0,
        95
      );

      doc.myText(
        this.liensData.params["Client Address"],
        { align: "center" },
        0,
        104
      );
      doc.myText(
        this.liensData.params["Matter Number"],
        { align: "center" },
        0,
        112
      );
    },
    secondOfSecond(doc, yMargin = 240, mainX = 17) {
      doc.setFont("normalFont", "normal");
      doc.setFontSize(9);
      let arr = [
        //["Amounts owed since:", 0],
        [
          `Assessments from ${this.liensData.divided.POST["WP POST Assessement first DA"]} to ${this.liensData.divided.POST["WP POST Assessment last date DA"]} :`,
          0
        ],
        [
          `Accelerated Assessments through ${this.liensData.divided.POST["WP POST Last day of year DA"]} :`,
          0
        ],
        ["Misc Charges:", 0],
        ["Late Fees:", 0],
        ["Interest:", 0],
        ["Attorney’s Fees:", 0],
        ["Costs:", 0],
        ["Fines:", 0],
        ["Adjustment Credits:", 0],
        ["Payments:", 0]
      ];
      this.rowList(18, 70, 0, mainX, yMargin + 10, arr, doc);
      doc.text("Total:", mainX, yMargin + 260);
      doc.setFont("boldFont", "bold");
      doc.text("Lien Total:", mainX, yMargin + 278);
    },
    answersOfSecond(doc, yMargin = 250, mainX = 197) {
      doc.setFont("normalFont", "normal");
      doc.setFontSize(9);
      let arr = [
        // [
        //   this.liensData.divided.POST[
        //     "WP POST First entry in post judgment DA"
        //   ],
        //   0
        // ],
        [
          // ["from:", -20],
          // [this.liensData.divided.POST["WP POST Assessement first DA"], 0],
          // ["to:", 60],
          // [this.liensData.divided.POST["WP POST Assessment last date DA"], 70],
          // [
          this.liensData.divided.POST["WP POST Assessement ledger total CUR"],
          0
          // ]
        ],
        [
          this.liensData.divided.POST[
          "WP POST Accelerated assessment total CUR"
          ],
          0
        ],
        [this.liensData.divided.POST["WP POST Ledger misc total CUR"], 0],
        [this.liensData.divided.POST["WP POST Late fee total ledger CUR"], 0],
        [this.liensData.divided.POST["WP POST Ledger interest total CUR"], 0],
        [
          this.liensData.divided.POST["WP POST Attorney fees ledger total CUR"],
          0
        ],
        [this.liensData.divided.POST["WP POST Ledger cost total CUR"], 0],
        [this.liensData.divided.POST["WP POST Ledger fine total CUR"], 0],
        [
          this.liensData.divided.POST[
          "WP POST Ledger settlement credits total CUR"
          ],
          0,
          true
        ],
        [
          this.liensData.divided.POST["WP POST Payments ledger tool CUR"],
          0,
          true
        ]
      ];
      this.rowList(18, 70, 0, mainX, yMargin + 10, arr, doc);
      this.addRow(
        this.liensData.divided.POST["WP POST Ledger running balance total CUR"],
        mainX,
        yMargin + 260,
        doc
      );
      doc.setFont("boldFont", "bold");
      this.addRow(
        this.liensData.divided.POST["WP POST Lien total CUR"],
        mainX,
        yMargin + 278,
        doc
      );
      // doc.text(
      //   `${this.liensData.divided.POST["WP POST Lien total CUR"] || 0}`,
      //   mainX,
      //   yMargin + 285
      // );
    },
    secondPanel(doc) {
      const only = this.liensData.noPrior;
      const mainY = only ? 120 : 380;
      const y = only ? 70 : 332;
      doc.setTextColor("#000");
      doc.setFontSize(15);
      doc.setFont("boldFont", "bold");
      doc.myText(
        //only ? "CLAIM SUMMARY" : "SUMMARY",
        "Current Amount Owed",
        { align: "center" },
        0,
        mainY
      );
      doc.setFont("normalFont", "normal");
      doc.setFontSize(9);
      doc.myText(
        "(since " +
        this.liensData.divided.POST[
        "WP POST First entry in post judgment DA"
        ] +
        ")",
        { align: "center" },
        3,
        mainY + 10
      );
      //titles
      this.secondOfSecond(doc, y);
      //answers
      this.answersOfSecond(doc, y);
    },
    disclaimer(doc) {
      const mainY = this.liensData.noPrior ? 380 : 20;
      if (!this.liensData.noPrior) {
        doc.addPage("A4", "portrait");
      }
      doc.setTextColor("#000");
      doc.setFontSize(15);
      doc.setFont("boldFont", "bold");
      doc.myText("Disclaimer", { align: "center" }, 0, mainY);
      doc.setFontSize(9);
      doc.setFont("normalFont", "normal");
      const pdfMargin = 410;
      const xMargin = 17;
      doc.text(
        xMargin,
        mainY + 20,
        doc.splitTextToSize(
          "The collections balance shown above may or may not reflect an accurate balance owed by the owner. The Claim Summary has been made available for informational purposes only, may not be used as a payoff figure, and should not be shared with the owner.",
          pdfMargin
        )
      );
      doc.text(
        xMargin,
        mainY + 40,
        doc.splitTextToSize(
          "An owner’s current balance is generally comprised of the following components depending upon the association’s governing documents: assessments, late charges, interest, attorney’s fees, and collection costs. These amounts change on a daily basis. In order to show an accurate balance, a new account ledger and attorney fee statement would need to be reviewed daily. Interest would also need to be recalculated. This is not practical and would divert resources better spent undertaking collection efforts. We instead obtain a current ledger from the association or its management company at different stages in our collections and update the owner’s collections balance with those unpaid assessments at those times, such as, the initial collection letter, the lien, the warning of lawsuit, the lawsuit, a settlement agreement, and motion for judgment against the owner.",
          pdfMargin
        )
      );
      doc.text(
        xMargin,
        mainY + 100,
        doc.splitTextToSize(
          "For the same reasons as mentioned above, it is unlikely that the balance our office shows as due and owing will be the same balance shown on the association’s account ledger. Again, it is simply not feasible for the community manager to immediately update an account ledger with legal fees and costs incurred by the law firm each day.  In addition, the collections balance in our system may also include accelerated assessments that might not be shown on the association’s ledger.",
          pdfMargin
        )
      );
      doc.text(
        xMargin,
        mainY + 140,
        doc.splitTextToSize(
          "Please be assured that our office is seeking the correct balance owed by each owner who is put into collections.  Each time we provide a balance to the owner, all assessments, late charges, interest, attorney's fees, and collection costs owed to the association are being included. We have included this note to avoid any confusion or misunderstanding regarding our collections balance. If you have any questions on a owner’s balance, please contact us.",
          pdfMargin
        )
      );
    },
    async loadPdf(token, n = true) {
      //condition of getting error
      //condition of empty
      try {
        if (n) {
          this.ltimes = 0;
        }
        let res = await lauderLarken.getPDF(token);
        const data = res && res.data;
        this.ltimes += 1;
        if ("error" in data) {
          this.$store.commit("snackbar", {
            appear: true,
            timeout: 5000,
            text: "HotDocs is stopped or unavilable",
            color: "warning"
          });
          return null;
        }

        if (!data || Object.keys(data).length === 0) {
          if (this.ltimes >= 2) {
            this.$store.commit("snackbar", {
              appear: true,
              timeout: 5000,
              text: "server is currntly busy, try again in 30 seconds",
              color: "warning"
            });
            return null;
          }

          const r = new Promise(resolve => {
            setTimeout(async () => {
              console.log("finished waiting");
              resolve(await this.loadPdf(token, false));
            }, 11000);
          });
          return await r;
          // return null;
        }
        return data;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async lienHandler(params) {
      try {
        if (
          params.data["Liens"] === 1 ||
          (!hasALocalPyServer && process.env.NODE_ENV === "development")
        ) {
          console.log('entered')
          // let res = await lauderLarken.getPDF(params.data["row_sys"]);
          const data = await this.loadPdf(params.data["row_sys"]);

          //console.log((data == nuul));
          if (data) {
            const result = {
              JDG: { 1: {}, 2: {}, 3: {} },
              POST: {}
            };
            for (const property in data || {}) {
              // console.log(data[property]);
              // console.log(property);
              data[property] =
                //isNaN(data[property]) != true
                typeof data[property] == "number" &&
                  property !== "WP JDG Prior CNT"
                  ? "$" +
                  parseFloat(data[property])
                    .toFixed(2)
                    .toString()
                  : data[property];
              // console.log(data[property]);
              // console.log(isNaN(data[property]));
              // console.log(property);

              if (property.includes("JDG")) {
                if (property.includes("JDG1")) {
                  result.JDG[1][property] = data[property];
                } else if (property.includes("JDG2")) {
                  result.JDG[2][property] = data[property];
                } else if (property.includes("JDG3")) {
                  result.JDG[3][property] = data[property];
                } else {
                  result.JDG[property] = data[property];
                }
              } else if (property.includes("POST")) {
                result.POST[property] = data[property];
              } else {
                result[property] = data[property];
              }
            }
            this.liensData.data = data;
            this.liensData.params = params.data;
            this.liensData.divided = result;
            //start the report
            const doc = new jsPDF("p", "px", "A4");
            this.addFonts(doc);
            //header
            this.header(doc);
            //first panel
            this.firstPanel(doc);
            //second panel
            this.secondPanel(doc);
            this.disclaimer(doc);
            //save report
            const fileName = params.data["row_sys"] + ".pdf";
            doc.save(this.$options.filters.fileName(fileName));
            this.liensData = {};
          }
          // const link = document.createElement("a");
          // link.download = fileName; // add attribute to a tag
          // link.style.display = "none";
          // link.href = URL.createObjectURL(blob);
          // document.body.appendChild(link);
          // link.click();
          // URL.revokeObjectURL(link.href); // release url
          // if (document.contains(link)) {
          //   document.body.removeChild(link);
          // }
        } else {
          this.$store.commit("snackbar", {
            appear: true,
            timeout: 5000,
            text: "No liens file. ",
            color: "warning"
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
  components: {
    specific_association: () => import("@/views/asssociation"),
    grid_v_card: () => import("@/components/grid_v_card")
  }
};
</script>

<style scoped>
.selectdiv {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

.flex-item {
  height: 1%;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

/* This is the class name given by the Font Awesome component when icon contains 'spinner' */
.fa-spinner {
  /* Apply 'spinner' keyframes looping once every second (1s)  */
  animation: spinner 1s linear infinite;
}
.selectdiv.row {
    min-height: 100px;
}
.selectdiv.row {
  background-color: red !important;
  min-height: 100px;
  display: contents !important;
}
</style>
